import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button, Stack } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLoginStore } from "../../store/loginPageStore/loginStore";
import { LoadingButton } from "@mui/lab";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface ForgetPasswordModalProps {
  open: boolean;
  onClose: () => void;
}

interface FormData {
  mobileNumber: string;
  otp: string;
  password: string;
  confirmPassword: string;
}

const ForgetPasswordModal: React.FC<ForgetPasswordModalProps> = ({
  open,
  onClose,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const {
    handleInitiateOTP,
    handleUpdatePassword,
    otpSent,
    initiateOTPLoading,
    resetPasswordLoading,
    handleReset,
  } = useLoginStore();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      mobileNumber: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
  });

  const mobileNumber = watch("mobileNumber");

  useEffect(() => {
    handleReset();
  }, [open]);

  const handleSendOtp = () => {
    if (mobileNumber) {
      handleInitiateOTP(mobileNumber, onClose);
    }
  };

  const handleResetPassword = (data: FormData) => {
    let payload = {
      mobile: data.mobileNumber,
      otp: data.otp,
      password: data.password,
      confirm_password: data.confirmPassword,
    };
    handleUpdatePassword(payload, reset(), onClose());
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 3,
          mx: "auto",
          mt: "10%",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" mb={2}>
          {otpSent ? "Reset Password" : "Forgot Password"}
        </Typography>

        {!otpSent && (
          <form onSubmit={handleSubmit(handleSendOtp)}>
            <Stack spacing={2}>
              <Controller
                name="mobileNumber" // Assuming this is the mobile number field
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    placeholder="Enter Mobile Number"
                    size="small"
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, ""); // Allow only digits
                      if (newValue.length <= 10) {
                        onChange(newValue);
                      }
                    }}
                    error={!!error}
                    helperText={error?.message}
                    inputProps={{ maxLength: 10 }} // Prevent more than 10 characters
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Please enter a valid 10-digit mobile number",
                  },
                }}
              />
              <LoadingButton
                loading={initiateOTPLoading}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Send OTP
              </LoadingButton>
            </Stack>
          </form>
        )}

        {otpSent && (
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <Stack spacing={2}>
              <Controller
                name="mobileNumber" // Assuming this is the mobile number field
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, ""); // Allow only digits
                      if (newValue.length <= 10) {
                        onChange(newValue);
                      }
                    }}
                    error={!!error}
                    helperText={error?.message}
                    inputProps={{ maxLength: 10 }} // Prevent more than 10 characters
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Please enter a valid 10-digit mobile number",
                  },
                }}
              />
              <Controller
                name="otp"
                control={control}
                rules={{
                  required: "OTP is required",
                  pattern: {
                    value: /^[0-9]{6}$/,
                    message: "Invalid OTP",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="OTP"
                    type="text"
                    variant="outlined"
                    fullWidth
                    error={!!errors.otp}
                    helperText={errors.otp?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <LoadingButton
                loading={resetPasswordLoading}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Reset Password
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ForgetPasswordModal;
