import { Button, Grid, Typography } from "@mui/material";
import IMAGES from "../../assets/images";
import LoginForm from "../components/login/LoginForm";
import { useState } from "react";

const LoginPage: React.FC = () => {
  return (
    <Grid container height={"100vh"} gap={15}>
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        <img src={IMAGES.LoginFormImage} />
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        <LoginForm />
      </Grid>
    </Grid>
  );
};
export default LoginPage;
