import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import ImageComponent from "../../ImageComponent";
import AddDocumentModal from "./AddDocumentModal";
import UpdateDocumentModal from "./UpdateDocumentModal";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";
import "../../../../components/patient-information/OncologyPageStyles.css";

const DocumentCard: React.FC<{
  document: any;
  isBlurred: boolean;
  onUpdate: Function;
}> = ({ document, isBlurred, onUpdate }) => (
  <Grid item xs={6}>
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="body1" fontWeight={600}>
            {document.document_type}
          </Typography>
        }
      />
      <ImageComponent uuid={document.uuid} />
      <Stack direction="row" spacing={1} marginLeft={1}>
        <Typography variant="subtitle2" fontWeight="bold">
          Document Group:
        </Typography>
        <Typography variant="subtitle2">{document.document_group}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} marginLeft={1}>
        <Typography variant="subtitle2" fontWeight="bold">
          Status:
        </Typography>
        <Typography variant="subtitle2">{document.status}</Typography>
      </Stack>
      {!isBlurred && (
        <Button variant="text" color="secondary" onClick={() => onUpdate()}>
          Update
        </Button>
      )}
    </Card>
  </Grid>
);

const ProgramDocs: React.FC = () => {
  const { selectedCard, isBlurred } = useEnrollmentMainStore();
  const {
    fetchProgramDocuments,
    handleAddNewDocumentModal,
    handleUpdateDocumentModal,
    handleRefreshUrl,
    showAddNewDocumentModal,
    showUpdateDocumentModal,
  } = useDocumentStore();
  const {
    captchaToken: fetchProgramDocumentsToken,
    executeRecaptcha: executeFetchProgramDocumentsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramDocuments");

  useEffect(() => {
    executeFetchProgramDocumentsRecaptcha();
    handleRefreshUrl();
  }, [handleRefreshUrl]);

  useEffect(() => {
    if (fetchProgramDocumentsToken) {
      fetchProgramDocuments(
        selectedCard.program.id,
        fetchProgramDocumentsToken
      );
    }
  }, [
    fetchProgramDocumentsToken,
    fetchProgramDocuments,
    selectedCard.program.id,
  ]);

  return (
    <Stack overflow="scroll" height="60vh">
      {!isBlurred && (
        <Stack
          direction="row"
          justifyContent="end"
          bgcolor="white"
          position="fixed"
          zIndex={"9999"}
          width="41%"
          padding={1}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => handleAddNewDocumentModal(true)}
          >
            <Typography variant="subtitle2" fontWeight="bold">
              Add New Document
            </Typography>
          </Button>
        </Stack>
      )}
      <Grid container spacing={4} mt={4}>
        {selectedCard.documents.map((document, index) => (
          <DocumentCard
            key={index}
            document={document}
            isBlurred={isBlurred}
            onUpdate={handleUpdateDocumentModal}
          />
        ))}
      </Grid>
      {showAddNewDocumentModal && <AddDocumentModal />}
      {showUpdateDocumentModal && <UpdateDocumentModal />}
    </Stack>
  );
};

export default ProgramDocs;
