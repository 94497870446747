import {
  Autocomplete,
  Box,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AppointmentCardHeader from "./AppointmentCardHeader";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { GaChip } from "../../../GaChip";
import GaTextArea from "../../../GaTextArea";
import GaButton from "../../../GaButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import AddDocumentModal from "../program-documents/AddDocumentModal";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { AppointmentType } from "../../../../../domain/models/appointment-module/fetch-appointment-response";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { UserType } from "../../../../../domain/models/appointment-module/fetch-users-response";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import Swal from "sweetalert2";
import { AppointmentTypeEnum } from "../../../../../constants/enums/appointmentType";
import { Constant } from "../../../../../constants/constant";
import ImageCard from "../../../ImageCard";
import moment from "moment";
import { AppointmentStatus } from "../../../../../constants/enums/appointmentStatus";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";

interface CreateAppointmentProps {
  appointment?: AppointmentType;
  saveAppointment?: Function;
}

const appointmentTypes = [{ type: "Online" }, { type: "In-person" }];

const CreateAppointment: React.FC<CreateAppointmentProps> = ({
  appointment,
  saveAppointment,
}) => {
  const {
    handleAddNewDocumentModal,
    fetchProgramDocuments,
    showAddNewDocumentModal,
  } = useDocumentStore();

  const {
    captchaToken: fetchProgramDocumentsToken,
    executeRecaptcha: executeFetchProgramDocumentsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramDocuments");
  const {
    fetchDoctorsForAppointment,
    doctorsForAppointmentData,
    fetchDoctorsForAppointmentLoading,
    fetchDoctorSlots,
    doctorSlots,
    fetchDoctorSlotsLoading,
    createAppointment,
    editAppointment,
    imagesToUploads,
    fetchAppointmentDocumentTypes,
  } = useAppointmentDetailsStore();
  const { selectedCard } = useEnrollmentMainStore();

  dayjs.extend(customParseFormat);
  const borderColor = appointment ? "#EB2525" : "#A2BCF6";
  const bgColor = appointment ? "#FFFAFA" : "white";

  const defaultProps = {
    options: appointment
      ? [
          {
            ...appointment.appointee,
          },
        ]
      : doctorsForAppointmentData,
    getOptionLabel: (option: UserType) => option.name,
  };

  const [appointmentData, setAppointmentData] = useState({
    date: null as Dayjs | null,
    doctors: null as UserType | null,
    appointmentType: 0,
    selectedTimeSlot: null as any | null,
    reason: "",
    clinic: null as UserType | null,
  });

  const handleAppointmentTypeChange = (newValue: number) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      appointmentType: newValue,
    }));
  };

  const handleTimeSlotChange = (newValue: any | null) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      selectedTimeSlot: newValue,
    }));
  };

  const handleSave = () => {
    Swal.fire({
      title: "Response Loading...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const creatAppointmentPayload = {
      appointee_id: appointmentData?.doctors?.id,
      requester_uuid: selectedCard.patient_uuid,
      requester_name: selectedCard.patient_full_name,
      requester_mobile: selectedCard.mobile,
      appointment_date: appointmentData?.date?.format(
        Constant.YEAR_MONTH_DATE_FORMAT
      ),
      appointee_time_slot_id: appointmentData?.selectedTimeSlot,
      type:
        appointmentData?.appointmentType === 0
          ? AppointmentTypeEnum.Online
          : AppointmentTypeEnum.Offline,
      description: appointmentData.reason,
      meta_data: null,
    };

    createAppointment(creatAppointmentPayload);
  };

  const handleEdit = () => {
    Swal.fire({
      title: "Response Loading...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    saveAppointment && saveAppointment(false);
    const editAppointmentPayload = {
      appointment_date: appointmentData?.date?.format(
        Constant.YEAR_MONTH_DATE_FORMAT
      ),
      appointee_time_slot_id: appointmentData?.selectedTimeSlot,
      type:
        appointmentData?.appointmentType === 0
          ? AppointmentTypeEnum.Online
          : AppointmentTypeEnum.Offline,
      description: appointmentData.reason,
      meta_data: null,
    };
    editAppointment(appointment?.id, editAppointmentPayload);
  };

  const isAppointmentDataValid = () => {
    return Object.entries(appointmentData).every(([key, value]) => {
      if (key === "clinic") {
        return true;
      }
      return value !== null && value !== "";
    });
  };

  useEffect(() => {
    if (appointment) {
      const headerDate = appointment.date;
      const format = Constant.YEAR_MONTH_DATE_FORMAT;
      const parsedDate = dayjs(headerDate, format);

      setAppointmentData({
        date: parsedDate,
        doctors: { ...appointment.appointee },
        appointmentType:
          appointment.type === AppointmentTypeEnum.Online ? 0 : 1,
        selectedTimeSlot: appointment.appointee_time_slot_id,
        reason: appointment.description,
        clinic: { ...appointment.appointee },
      });
    }
    fetchDoctorsForAppointment({ user_type: "APPOINTEE" });
    executeFetchProgramDocumentsRecaptcha();
    useAppointmentDetailsStore.getState().imagesToUploads = [];
  }, []);

  useEffect(() => {
    if (fetchProgramDocumentsToken) {
      fetchProgramDocuments(
        selectedCard.program.id,
        fetchProgramDocumentsToken
      );
    }
  }, [fetchProgramDocumentsToken]);

  useEffect(() => {
    if (appointmentData.doctors && appointmentData.date) {
      fetchDoctorSlots(appointmentData.doctors.id, {
        date: appointmentData.date.format(Constant.YEAR_MONTH_DATE_FORMAT),
      });
    }
  }, [appointmentData.doctors, appointmentData.date]);

  return (
    <Box
      width={"99%"}
      border={`2px solid ${borderColor}`}
      borderRadius={"20px"}
      mb={1}
      bgcolor={bgColor}
    >
      {appointment ? (
        <AppointmentCardHeader
          id={String(appointment.id)}
          name={appointment.appointee.name + " (Editing)"}
          date={
            appointment.date +
            " : " +
            moment(appointment.start_time, Constant.HOUR_MIN_SEC_FORMAT).format(
              Constant.HOUR_MIN_FORMAT
            )
          }
        />
      ) : (
        <Stack
          justifyContent={"center"}
          padding={"10px 15px 10px 15px"}
          bgcolor="#618FF3"
          borderBottom={"1px solid #E8E6E3"}
          borderRadius={"18px 18px 0px 0px"}
          minHeight={"30px"}
        >
          <Typography
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={1}
            color={"white"}
          >
            Add Appointment
          </Typography>
        </Stack>
      )}
      <Stack direction={"column"} spacing={1} p={2}>
        <InputLabel htmlFor="doctor-search">Search Doctor</InputLabel>
        <Autocomplete
          id="doctor-search"
          {...defaultProps}
          value={appointmentData.doctors}
          onChange={(event: any, newValue: UserType | null) => {
            setAppointmentData((prevData) => ({
              ...prevData,
              doctors: newValue,
            }));
          }}
          size="small"
          loading={fetchDoctorsForAppointmentLoading === FetchState.LOADING}
          disabled={appointment ? true : false}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search for doctor"
              required
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "grey" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
        />
        <InputLabel>Date for Appointment</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="ll"
            value={appointmentData.date}
            disablePast
            disabled={
              !appointmentData.doctors ||
              (appointment &&
                appointment.status === AppointmentStatus.Confirmed)
            }
            maxDate={dayjs().add(30, "day")}
            onChange={(newValue: any) =>
              setAppointmentData((prevData) => ({
                ...prevData,
                date: newValue,
                selectedTimeSlot: null,
              }))
            }
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                InputProps: {
                  readOnly: true,
                },
                placeholder: "Select Appointment Date",
              },
            }}
            sx={{
              backgroundColor: "white",
            }}
          />
        </LocalizationProvider>
        <Stack direction="row" flexWrap={"wrap"}>
          {appointmentData.doctors && appointmentData.date ? (
            <>
              {fetchDoctorSlotsLoading === FetchState.SUCCESS &&
                (doctorSlots?.length > 0 ? (
                  doctorSlots.map((slot, index) => (
                    <GaChip
                      key={slot.id}
                      text={`${slot.start_time.slice(
                        0,
                        -3
                      )} - ${slot.end_time.slice(0, -3)}`}
                      isDisabled={
                        slot.slot_status !== "vacant" ||
                        (appointment &&
                          appointment.status === AppointmentStatus.Confirmed)
                      }
                      onClick={() => handleTimeSlotChange(slot.id)}
                      isSelected={appointmentData.selectedTimeSlot === slot.id}
                      sx={{
                        margin: "5px",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        width: "120px",
                        height: "40px",
                        backgroundColor:
                          appointmentData.selectedTimeSlot === slot.id
                            ? "#2563EB"
                            : "white",
                      }}
                    />
                  ))
                ) : (
                  <Typography>No slots available</Typography>
                ))}
              {fetchDoctorSlotsLoading === FetchState.LOADING && (
                <Typography variant="caption" fontWeight={500} color={"red"}>
                  Loading...
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="caption" fontWeight={500} color={"red"}>
              Please select a doctor and a date to view the available slots.
            </Typography>
          )}
        </Stack>
        <InputLabel htmlFor="reason-for-appointment">
          Reason for Appointment
        </InputLabel>
        <GaTextArea
          minRows={4}
          maxRows={5}
          placeholder="Enter Reason..."
          value={appointmentData.reason}
          onChange={(e) => {
            const input = e.target.value;
            if (input.length <= 500) {
              setAppointmentData((prevData) => ({
                ...prevData,
                reason: input,
              }));
            }
          }}
        />
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <InputLabel>Select Type of Appointment</InputLabel>
          {appointmentTypes.map((appointmentType, index) => (
            <GaChip
              key={index}
              text={appointmentType.type}
              onClick={() => handleAppointmentTypeChange(index)}
              isSelected={appointmentData.appointmentType === index}
              isDisabled={
                appointment &&
                appointment.status === AppointmentStatus.Confirmed
              }
              sx={{
                margin: "5px",
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                width: "90px",
                height: "35px",
                backgroundColor:
                  appointmentData.appointmentType === index
                    ? "#2563EB"
                    : "white",
              }}
            />
          ))}
        </Stack>
        {/* {appointmentData.appointmentType === 1 && (
          <>
            <InputLabel htmlFor="clinic-search">Search Clinic</InputLabel>
            <Autocomplete
              id="clinic-search"
              {...defaultProps}
              value={appointmentData.clinic}
              onChange={(event: any, newValue: UserType | null) => {
                setAppointmentData((prevData) => ({
                  ...prevData,
                  clinic: newValue,
                }));
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Clinic"
                  size="small"
                  required
                  sx={{
                    backgroundColor: "white",
                    width: "70%",
                  }}
                />
              )}
            />
          </>
        )} */}

        <Stack direction={"column"} spacing={1}>
          <Stack width={"100%"}>
            <InputLabel>Uploads</InputLabel>
            <Stack flexWrap={"wrap"} direction={"row"} spacing={1}>
              {imagesToUploads.map((fileItem) => (
                <ImageCard uuid={fileItem.uuid} title={fileItem.title} />
              ))}
            </Stack>
            <Stack
              onClick={() => {
                fetchAppointmentDocumentTypes();
                handleAddNewDocumentModal(true);
              }}
              border={"1px dashed #B2BCC9"}
              borderRadius={"8px"}
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={"white"}
              p={1}
              boxShadow={"0px 1px 2px 0px #1018280F"}
              width={"60%"}
            >
              <Stack
                width={"50px"}
                height={"50px"}
                borderRadius={"25px"}
                bgcolor={"#E9FAEF"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CloudUploadRoundedIcon
                  sx={{ fontSize: "30px", color: "#22C55E" }}
                />
              </Stack>
              <Typography fontSize={"14px"}>
                Click to upload prescription/reports
              </Typography>
              <Typography fontSize={"14px"} color={"#7D8A95"}>
                PNG, JPG OR PDF (Max. 2MB)
              </Typography>
            </Stack>
          </Stack>
          {appointment && appointment.documents && (
            <Stack width={"100%"}>
              <InputLabel>Uploaded</InputLabel>
              <Stack flexWrap={"wrap"} direction={"row"} spacing={1}>
                {appointment.documents.map((fileItem) => (
                  <ImageCard
                    key={fileItem.id}
                    uuid={fileItem.uuid}
                    title={fileItem.title}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <GaButton
            text={!appointment ? "Save" : "Update"}
            onClick={() => {
              !appointment ? handleSave() : handleEdit();
            }}
            isDisabled={!isAppointmentDataValid()}
            startIcon={
              <AddCircleRoundedIcon sx={{ fontSize: "14px", color: "white" }} />
            }
            buttonBackgroundColor="#2563EB"
            textColor="white"
          />
          {appointment && (
            <GaButton
              text="Close"
              onClick={() => {
                saveAppointment && saveAppointment(false);
              }}
              buttonBackgroundColor="#FEF3F2"
              textColor="#F63D68"
            />
          )}
        </Stack>
      </Stack>
      {showAddNewDocumentModal && <AddDocumentModal source="appointment" />}
    </Box>
  );
};

export default CreateAppointment;
