import {
  Button,
  Card,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDocumentStore } from "../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import useRecaptcha from "../hooks/reCaptcha";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../base";
import axios from "axios";
import pdfThumbnail from "../../assets/images/pdf.png";
import CloseIcon from "@mui/icons-material/Close";

const uploadCard = {
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "95%",
};

type Props = {
  uuid: string | null;
  title: string;
  id: number;
  file: any;
  onFileSelect: Function;
  reset: () => void;
  isLoading: boolean;
  error?: boolean;
  helperText?: string;
  message?: string;
  isConsent?: boolean;
  cameraInputRef: any;
  galleryInputRef: any;
  handleOpenModal: Function;
  refName?: string;
  documentGroupType: string;
};

const UploadFileCard: React.FC<Props> = ({
  uuid,
  title,
  id,
  file,
  onFileSelect,
  reset,
  isLoading = false,
  error = false,
  helperText = "",
  message = "",
  isConsent = false,
  cameraInputRef,
  handleOpenModal,
  galleryInputRef,
  refName = "",
  documentGroupType,
}) => {
  const { getProgramDocs, url } = useDocumentStore();
  const [isPdf, setIsPdf] = useState(false);
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const {
    captchaToken: getProgramDocsToken,
    executeRecaptcha: executeRecaptchaGetProgramDocs,
    isCaptchaLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "getProgramDocs");

  useEffect(() => {
    if (getProgramDocsToken) {
      getProgramDocs(uuid, getProgramDocsToken);
    }
  }, [getProgramDocsToken]);

  const getDocumentUrl = useCallback(async () => {
    if (uuid && !file) {
      executeRecaptchaGetProgramDocs();
    }
  }, [uuid, file]);

  useEffect(() => {
    if (uuid) getDocumentUrl();
  }, [uuid]);

  const findUrl = (imageUuid: string) => {
    const imageURL = url.find((item) => item.uuid == imageUuid);
    return imageURL?.url;
  };

  useEffect(() => {
    const updatePdfStatus = async (imageUrl: string) => {
      try {
        const response = await axios.get(imageUrl, { responseType: "blob" });
        const contentType = response.headers["content-type"];
        setIsPdf(contentType === "application/pdf");
      } catch (error) {
        console.error("Error checking file type:", error);
        setIsPdf(false);
      }
    };

    if (uuid && url.length > 0) {
      const imageUrl = findUrl(uuid);
      updatePdfStatus(imageUrl ?? "");
    }
  }, [uuid, url]);

  const renderPreview = (url: string) => {
    if (isPdf) {
      return (
        <iframe
          src={url}
          title="PDF Preview"
          style={{ width: "100%", height: "100%" }}
        />
      );
    }
    return (
      <img
        src={url}
        alt={title}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    );
  };

  if (url.length > 0 && uuid) {
    return (
      <>
        <Card variant="outlined">
          <Stack
            flexDirection={"row"}
            spacing={1}
            width={"100%"}
            alignItems={"center"}
          >
            {isPdf ? (
              <img
                src={pdfThumbnail}
                alt="PDF Thumbnail"
                width="10%"
                height={"10%"}
                onClick={() => setPreviewOpen(true)}
              />
            ) : (
              <img
                src={findUrl(uuid)}
                alt={title}
                width="10%"
                height={"10%"}
                onClick={() => setPreviewOpen(true)}
              />
            )}

            <Typography
              padding={2}
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {title}
            </Typography>
          </Stack>
        </Card>
        <Dialog
          open={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
          fullScreen
          sx={{
            "& .MuiPaper-root": {
              height: "100vh",
              width: "100vw",
              position: "relative",
            },
          }}
        >
          {/* Close Icon */}
          <IconButton
            onClick={() => setPreviewOpen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1000,
              background: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              "&:hover": { background: "rgba(0, 0, 0, 0.7)" },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Preview Content */}
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            {renderPreview(findUrl(uuid) ?? "")}
          </div>
        </Dialog>
      </>
    );
  }
  if (file)
    return (
      <UploadedFileCard
        file={file}
        reset={reset}
        isConsent={isConsent}
        docType={documentGroupType}
      />
    );
  else
    return (
      <FormControl error={error}>
        <FormHelperText sx={{ margin: 0 }}>
          <Typography variant="subtitle2">{message} </Typography>
        </FormHelperText>
        <Card variant={"outlined"}>
          <div style={uploadCard}>
            <Typography variant="subtitle2">{title}</Typography>

            <input
              ref={cameraInputRef}
              capture="environment"
              accept="image/*"
              type="file"
              id={"consent" + id.toFixed()}
              style={{ display: "none" }}
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file) {
                  onFileSelect(file);
                }
                e.target.value = "";
              }}
            />

            <input
              ref={galleryInputRef}
              accept="image/*, application/pdf"
              type="file"
              id={"consent" + id.toFixed()}
              style={{ display: "none" }}
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file) {
                  onFileSelect(file);
                }
                e.target.value = "";
              }}
            />
            <Stack>
              <Button
                sx={{ color: "#fff" }}
                variant="contained"
                color="secondary"
                disabled={isConsent}
                id={"consent" + id.toFixed()}
                component="span"
                onClick={() =>
                  handleOpenModal(true, refName, documentGroupType)
                }
              >
                {isLoading ? "Uploading..." : "Capture"}
              </Button>
            </Stack>
          </div>
        </Card>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
};

type UploadedProps = {
  file: File;
  reset: () => void;
  isConsent?: boolean;
  docType: string;
};

//TODO:: instead of hard coding the width and height it should be from style file.
const UploadedFileCard: React.FC<UploadedProps> = ({
  file,
  reset,
  isConsent = false,
  docType,
}) => {
  const isImage = file.type.startsWith("image/");
  const isPDF = file.type === "application/pdf";
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const renderThumbnail = () => {
    if (isImage) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Uploaded File"
          width="20%"
          style={{ cursor: "pointer" }}
          onClick={() => setPreviewOpen(true)}
        />
      );
    } else if (isPDF) {
      return (
        <div
          style={{ width: "20%", textAlign: "center", cursor: "pointer" }}
          onClick={() => setPreviewOpen(true)}
        >
          <img src={pdfThumbnail} alt="PDF Thumbnail" width="100%" />
          <Typography variant="caption">PDF File</Typography>
        </div>
      );
    }
    return null;
  };

  const renderPreview = () => {
    if (isImage) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Preview"
          style={{ width: "100%" }}
        />
      );
    } else if (isPDF) {
      return (
        <iframe
          src={URL.createObjectURL(file)}
          style={{ width: "100%", height: "100%" }}
          title="PDF Preview"
        />
      );
    }
    return null;
  };
  return (
    <>
      <Card variant={"outlined"}>
        <div style={uploadCard}>
          {file && renderThumbnail()}
          <Stack width={isConsent ? "80%" : "50%"}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {docType}
            </Typography>
          </Stack>
          {!isConsent && (
            <Button
              sx={{ color: "#fff" }}
              variant="contained"
              onClick={() => reset()}
              color="secondary"
              component="span"
            >
              Reset
            </Button>
          )}
        </div>
      </Card>
      <Dialog
        open={isPreviewOpen}
        onClose={() => setPreviewOpen(false)}
        fullScreen
        sx={{
          "& .MuiPaper-root": {
            height: "100vh",
            width: "100vw",
            position: "relative",
          },
        }}
      >
        {/* Close Icon */}
        <IconButton
          onClick={() => setPreviewOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1000,
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            "&:hover": { background: "rgba(0, 0, 0, 0.7)" },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Preview Content */}
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          {renderPreview()}
        </div>
      </Dialog>
    </>
  );
};

export default UploadFileCard;
