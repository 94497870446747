import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { AUTH_HEADER,USER_LOGIN_API } from "../../base";
import Endpoints from "../../domain/endpoints";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import { RemotePasswordReset } from "../../data/usecases/remote-password-reset";
import { PasswordReset } from "../../domain/usages/password-reset";


interface PasswordResetSliceType {
  handleInitiateOTP: Function;
  otpSent: boolean;
  initiateOTPLoading: boolean;
  handleReset: Function;
}
const initialStates = {
  initiateOTPLoading: false,
  otpSent: false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const usePasswordResetSlice: StateCreator<
  PasswordResetSliceType
  
> = (set) => ({
  ...initialStates,
  handleInitiateOTP: async (mobile: string, handleCloseModal: Function) => {
    const remotePasswordReset: PasswordReset =
      new RemotePasswordReset(
        `${USER_LOGIN_API}${Endpoints.PASSWORD_RESET}`,
        axiosHttpClient
      );
    set(() => ({
      initiateOTPLoading: true,
    }));
    let payload={
      "mobile":mobile
    }
    let result = await remotePasswordReset.initiate(payload);
    if (result.success) {
      set(() => ({
        otpSent: true,
        initiateOTPLoading: false,
      }));
  
      
    } else {
      handleCloseModal()
      set(() => ({
        initiateOTPLoading: false,
        otpSent: false
      }));
      if(result.errors.mobile){
        Swal.fire(result.errors.mobile, "", "error");
      } else{
        Swal.fire(result.message, "", "error");
      }
      
    }
  },
  handleReset:()=>{
    set(() => ({
      initiateOTPLoading: false,
      otpSent: false
    }));
  }
});
