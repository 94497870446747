import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { USER_LOGIN_API } from "../../base";
import Endpoints from "../../domain/endpoints";
import { RemoteUpdatePassword } from "../../data/usecases/remote-update-password";
import { UpdatePassword } from "../../domain/usages/update-password";



interface UpdatePasswordSliceType {
  handleUpdatePassword: Function;
  resetPasswordLoading: boolean;
}
const initialStates = {
  resetPasswordLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdatePasswordSlice: StateCreator<
  UpdatePasswordSliceType
> = (set, get) => ({
  ...initialStates,
  handleUpdatePassword: async (payload: UpdatePassword.Params, reset: Function, handleCloseModal: Function) => {
    // const loggedInUser = new LocalLoggedInUser(storage);
    // let token = loggedInUser.getToken();
    // axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token  });
    const remoteUpdatePassword: UpdatePassword =
      new RemoteUpdatePassword(
        `${USER_LOGIN_API}${Endpoints.UPDATE_PASSWORD}`,
        axiosHttpClient
      );
    set(() => ({
      resetPasswordLoading: true,
    }));
   
    let result = await remoteUpdatePassword.update(payload);
    if (result.success) {
      set(() => ({

        resetPasswordLoading: false,
      }));
      Swal.fire({
        title: "Password Updated successfully",
        icon: "success",
        timer: 2000,
      });
      reset();
      handleCloseModal();
      
    } else {
      set(() => ({
        resetPasswordLoading: false,
      }));
      if (result.errors && Object.keys(result.errors).length > 0) {
        const firstErrorKey = Object.keys(result.errors)[0]; // Get the first key from the errors object
        const firstErrorMessage = result.errors[firstErrorKey]; // Get the corresponding message
      
        Swal.fire({
          title: firstErrorMessage, // Display the first error message
          icon: "error",
          timer: 2000,
        });
      } else{
        Swal.fire(result.message, "", "error");
      }
     
      
    }
  },
});
