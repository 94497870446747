import { create } from "zustand";
import { useGenerateUserTokenSlice } from "./generateUserTokenSlice";
import { usePasswordResetSlice } from "./passwordResetSlice";
import { useUpdatePasswordSlice } from "./updatePasswordSlice";

interface LoginStoreType
  extends ReturnType<typeof useGenerateUserTokenSlice>,
    ReturnType<typeof usePasswordResetSlice>,
    ReturnType<typeof useUpdatePasswordSlice> {}

export const useLoginStore = create<LoginStoreType>()((...data) => ({
  ...useGenerateUserTokenSlice(...data),
  ...usePasswordResetSlice(...data),
  ...useUpdatePasswordSlice(...data),
}));
